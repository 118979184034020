<template>
  <div>
    <!-----------------------------------
    Start Header Component
    ------------------------------------>
    <Header></Header>
    <!-----------------------------------
    End Header Component
    ------------------------------------>

    <!-----------------------------------
    Start Body Component
    ------------------------------------>
    <!------------------CERTIFIEZ VOUS------------------>
    <div class="bg-carouselColor px-5">
      <div class="pt-48">
        <div class="flex justify-center text-center">
          <div
            class="w-5/6 font-bold lg:text-6xl lg:leading-normal text-4xl text-greenNew1"
          >
            {{ $t("slogan") }}
          </div>
        </div>
        <div class="flex justify-center text-center">
          <div class="lg:w-3/6 font-light text-lg leading-10 mt-6 px-5">
            {{ $t("but1") }}
          </div>
        </div>
        <div class="flex justify-center mt-12 mr-7 mb-16">
          <router-link to="/signup">
            <button
              class="font-semibold text-base leading-5 text-white bg-greenNew1 rounded-lg px-6 py-3.5 cursor-pointer"
            >
              {{ $t("buttonbase") }}
            </button>
          </router-link>
        </div>
      </div>
      <div class="flex justify-center">
        <div class="lg:w-3/4">
          <img :src="images.carousel" alt="carousel" original />
        </div>
      </div>
    </div>
    <!------------------OBJECTIFS & COMPETENCES VISEES------------------>
    <div id="objectifs" class="pt-20 px-5">
      <div
        class="font-semibold text-4xl leading-tight text-center text-gray-800"
      >
        {{ $t("objectifT") }}
      </div>
      <div
        class="font-normal text-xl leading-8 flex justify-center text-center mt-5 mb-10"
      >
        <div class="lg:w-3/5 text-gray-500 px-5">{{ $t("objectifP") }}</div>
      </div>
    </div>
    <!------------------QUELQUES OBJECTIFS & COMPETENCES VISEES------------------>
    <div>
      <div class="lg:px-20 px-5 lg:flex space-y-8 lg:space-x-8 md:mb-16 mb-8">
        <div class="lg:w-1/3 mt-8">
          <div class="flex justify-center items-center mb-5">
            <img
              :src="images.icon1"
              alt="icon1"
              width="48"
              height="48"
              original
            />
          </div>
          <div
            class="font-medium text-xl leading-8 text-gray-900 text-center mb-2"
          >
            {{ $t("objectifT1") }}
          </div>
          <div
            class="font-normal text-base leading-6 text-gray-500 text-center"
          >
            {{ $t("objectifP1") }}
          </div>
        </div>
        <div class="lg:w-1/3">
          <div class="flex justify-center items-center mb-5">
            <img
              :src="images.icon2"
              alt="icon2"
              width="48"
              height="48"
              original
            />
          </div>
          <div
            class="font-medium text-xl leading-8 text-gray-900 text-center mb-2"
          >
            {{ $t("objectifT2") }}
          </div>
          <div
            class="font-normal text-base leading-6 text-gray-500 text-center"
          >
            {{ $t("objectifP2") }}
          </div>
        </div>
        <div class="lg:w-1/3">
          <div class="flex justify-center items-center mb-5">
            <img
              :src="images.icon3"
              alt="icon3"
              width="48"
              height="48"
              original
            />
          </div>
          <div
            class="font-medium text-xl leading-8 text-gray-900 text-center mb-2"
          >
            {{ $t("objectifT3") }}
          </div>
          <div
            class="font-normal text-base leading-6 text-gray-500 text-center"
          >
            {{ $t("objectifP3") }}
          </div>
        </div>
      </div>

      <div class="px-20 lg:flex space-y-8 lg:space-x-8 mb-16">
        <div class="lg:w-1/3 mt-5">
          <div class="flex justify-center items-center mb-5">
            <img
              :src="images.icon4"
              alt="icon3"
              width="48"
              height="48"
              originalsta
            />
          </div>
          <div
            class="font-medium text-xl leading-8 text-gray-900 text-center mb-2"
          >
            {{ $t("objectifT4") }}
          </div>
          <div
            class="font-normal text-base leading-6 text-gray-500 text-center"
          >
            {{ $t("objectifP4") }}
          </div>
        </div>
        <div class="lg:w-1/3">
          <div class="flex justify-center items-center mb-5">
            <img
              :src="images.icon5"
              alt="icon4"
              width="48"
              height="48"
              original
            />
          </div>
          <div
            class="font-medium text-xl leading-8 text-gray-900 text-center mb-2"
          >
            {{ $t("objectifT5") }}
          </div>
          <div
            class="font-normal text-base leading-6 text-gray-500 text-center"
          >
            {{ $t("objectifP5") }}
          </div>
        </div>
        <div class="lg:w-1/3">
          <div class="flex justify-center items-center mb-5">
            <img
              :src="images.icon6"
              alt="icon5"
              width="48"
              height="48"
              original
            />
          </div>
          <div
            class="font-medium text-xl leading-8 text-gray-900 text-center mb-2"
          >
            {{ $t("objectifT6") }}
          </div>
          <div
            class="font-normal text-base leading-6 text-gray-500 text-center"
          >
            {{ $t("objectifP6") }}
          </div>
        </div>
      </div>
    </div>
    <!------------------PUBLICS CIBLES------------------>
    <div class="bg-gray-50 py-16">
      <div
        class="font-semibold text-4xl leading-tight text-center text-gray-800 mb-16 px-5"
      >
        {{ $t("publicsT") }}
      </div>
      <div class="lg:flex lg:px-28 px-5">
        <div
          class="lg:w-3/5 bg-white rounded-2xl border borde-gray-200 lg:mr-10 px-6 py-11 md:mb-0 mb-5"
        >
          <div class="flex justify-center items-center mb-6">
            <icon :data="icons.frame1" original width="130" height="130" />
          </div>
          <div
            class="font-semibold text-2xl leading-8 text-gray-900 text-center"
          >
            {{ $t("publicsT1") }}
          </div>
          <div
            class="font-normal text-base leading-6 text-gray-500 text-center"
          >
            {{ $t("publicsP1") }}
          </div>
        </div>
        <div
          class="lg:w-3/5 bg-white rounded-2xl border borde-gray-200 lg:mr-10 px-6 py-11"
        >
          <div class="flex justify-center items-center mb-6">
            <icon :data="icons.frame2" original width="130" height="130" />
          </div>
          <div
            class="font-semibold text-2xl leading-8 text-gray-900 text-center"
          >
            {{ $t("publicsT2") }}
          </div>
          <div
            class="font-normal text-base leading-6 text-gray-500 text-center"
          >
            {{ $t("publicsP2") }}
          </div>
        </div>
      </div>
    </div>
    <!---------------MOYENS PEDAGOGIQUES------------------>
    <div class="pt-20">
      <div
        class="font-semibold text-4xl leading-tight text-center text-gray-800"
      >
        {{ $t("moyensT") }}
      </div>
      <div
        class="font-normal text-xl leading-8 flex justify-center text-center mt-5 mb-16"
      >
        <div class="lg:w-3/5 text-gray-500">{{ $t("moyensP") }}</div>
      </div>
    </div>
    <div class="lg:flex items-center px-5 lg:px-28 pb-16">
      <div class="lg:w-3/5 lg:mr-24">
        <div class="font-semibold text-lg text-gray-900 mb-2">
          {{ $t("formatT") }}
        </div>
        <div class="font-normal text-base leading-7 text-gray-500 mb-5">
          {{ $t("formatP") }}
        </div>
        <div class="font-semibold text-lg text-gray-900 mb-2">
          {{ $t("comiteT") }}
        </div>
        <div class="font-normal text-base leading-7 text-gray-500 mb-5">
          {{ $t("comiteP") }}
        </div>
        <div class="flex space-x-20">
          <div>
            <div class="font-semibold text-lg text-gray-900 mb-2">
              {{ $t("dureeT") }}
            </div>
            <div class="font-normal text-base leading-7 text-gray-500 mb-5">
              {{ $t("dureeP") }}
            </div>
          </div>
          <div>
            <div class="font-semibold text-lg text-gray-900 mb-2">
              {{ $t("periodeT") }}
            </div>
            <div class="font-normal text-base leading-7 text-gray-500 mb-5">
              {{ $t("periodeP") }}
            </div>
          </div>
        </div>
        <div class="font-semibold text-lg text-gray-900 mb-2">
          {{ $t("evaluationT") }}
        </div>
        <div class="font-normal text-base leading-7 text-gray-500 mb-5">
          {{ $t("evaluationP") }}
        </div>
        <div class="font-semibold text-lg text-gray-900 mb-2">
          {{ $t("certificationT") }}
        </div>
        <div class="font-normal text-base leading-7 text-gray-500 mb-5">
          {{ $t("certificationP") }}
        </div>
      </div>
      <div class="lg:w-3/5">
        <img :src="images.image1" alt="carousel" original />
      </div>
    </div>
    <!---------------CONTENU DU PROGRAMME------------------>
    <div
      id="programme"
      class="font-semibold text-4xl leading-tight text-center text-gray-900 pt-16"
    >
      {{ $t("contenuT") }}
    </div>
    <div class="testimony lg:mx-28 mx-5 py-3 lg:py-16">
      <carousel :settings="settings" :breakpoints="breakpoints">
        <slide v-for="slide in testimonies" :key="slide">
          <div class="">
            <div
              class="car-item border-2 border-gray-200 h-400px hover:border-greenNew1 xl:hover:h-64 lg:hover:h-72 hover:h-64 rounded-2xl px-6 py-7 m-5"
            >
              <div
                class="font-semibold text-lg text-center text-purple-700 mb-4"
              >
                {{ slide.module }}
              </div>
              <div class="font-medium text-xl text-center text-gray-900 mb-3">
                {{ slide.title }}
              </div>
              <div
                class="font-normal text-base leading-loose text-gray-500 text-left"
                v-html="slide.point"
              ></div>
            </div>
          </div>
        </slide>

        <template #addons>
          <pagination />
        </template>
      </carousel>
    </div>
    <!---------------DRO6IT D'INSCRIPTION------------------>
    <div
      class="font-semibold text-4xl leading-tight text-center text-gray-900 py-6"
    >
      {{ $t("droitInscriptionT") }}
    </div>
    <div class="flex justify-center">
      <div>
        <div class="flex justify-center">
          <div class="lg:w-3/5 bg-gray-50 rounded-2xl px-5 lg:px-24 py-11 mx-5">
            <div class="font-normal text-base text-gray-500 text-center mb-2">
              {{ $t("droitInscriptionP1") }}
            </div>
            <div class="font-semibold text-sm text-gray-500 text-center mb-7">
              {{ $t("droitInscriptionTN") }}
            </div>
            <div
              class="font-semibold text-5xl lg:text-7xl leading-snug text-greenNew1 text-center mb-3"
            >
              399 euros (HT)
            </div>
            <div class="font-normal text-base text-gray-500 text-center mb-7">
              {{ $t("droitInscriptionP2") }}
            </div>
            <div class="flex justify-center mt-12">
              <router-link to="/signup">
                <button
                  class="font-semibold text-base leading-5 text-white bg-greenNew1 rounded-lg px-6 py-3.5 cursor-pointer"
                >
                  {{ $t("buttonbase") }}
                </button>
              </router-link>
            </div>
          </div>
        </div>
        <div class="flex justify-center">
          <div
            class="lg:w-3/5 font-medium text-base text-red-400 text-center my-7 mx-5"
          >
            {{ $t("placeDisponible") }}
          </div>
        </div>
      </div>
    </div>
    <!---------------RENSEIGNEMENT------------------>
    <div id="renseignements" class="bg-gray-50 py-24 px-5">
      <div
        class="font-semibold text-4xl leading-tight text-center text-gray-900 mb-5"
      >
        {{ $t("renseignements") }}
      </div>
      <div class="text-xl text-center mb-10">
        <span class="text-gray-500">Email</span> : contact@remaapp.com |<span
          class="text-gray-500"
        >
          WhatsApp</span
        >
        : +229 96508130
      </div>
      <div class="flex justify-center mr-7">
        <a href="https://learning.remaapp.com" target="_blank">
          <button
            class="font-semibold text-base leading-5 text-white bg-greenNew1 rounded-lg px-6 py-3.5 cursor-pointer"
          >
            {{ $t("button") }}
          </button>
        </a>
      </div>
    </div>
  </div>
  <!-----------------------------------
End Body Component
------------------------------------>
  <!-----------------------------------
Start Footer Component
------------------------------------>
  <Footer></Footer>
  <!-----------------------------------
End Footer Component
------------------------------------>
</template>

<script>
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Pagination } from "vue3-carousel";
/*Import Components */
import Header from "../../components/header/index.vue";
import Footer from "../../components/footer/index.vue";
/*Import Images*/
/*------------------IMAGES VOTRE COMMERCE AUX BOUTS DES DOIGTS------------------*/
import carousel from "../../assets/images/img_carousel.webp";
/*------------------OBJECTIFS & COMPETENCES VISEES------------------*/
import icon1 from "../../assets/images/img_icon1.webp";
import icon2 from "../../assets/images/img_icon2.webp";
import icon3 from "../../assets/images/img_icon3.webp";
import icon4 from "../../assets/images/img_icon4.webp";
import icon5 from "../../assets/images/img_icon5.webp";
import icon6 from "../../assets/images/img_icon6.webp";
import frame1 from "../../assets/icons/ic_frame1.svg";
import frame2 from "../../assets/icons/ic_frame2.svg";
/*------------------MOYENS PEDAGOGIQUES------------------*/
import image1 from "../../assets/images/img_image1.webp";
export default {
  name: "index",

  components: {
    Header,
    Footer,
    Carousel,
    Slide,
    Pagination,
  },

  data() {
    return {
      icons: { frame1, frame2 },
      images: {
        carousel,
        image1,
        icon1,
        icon2,
        icon3,
        icon4,
        icon5,
        icon6,
      },
      settings: {
        itemsToShow: 1,
        snapAlign: "center",
        itemsToScroll: 1,
      },
      // breakpoints are mobile first
      // any settings not specified will fallback to the carousel settings
      breakpoints: {
        // 700px and up
        700: {
          itemsToShow: 2,
          snapAlign: "center",
        },
        // 1024 and up
        1024: {
          itemsToShow: 2,
          snapAlign: "center",
        },
        // 1024 and up
        1280: {
          itemsToShow: 3,
          snapAlign: "center",
        },
      },

      testimonies: [
        {
          module: "Module 1",
          title: "FONDEMENTS DE LA SANTE PUBLIQUE",
          point:
            "• Introduction à la santé publique  <br /> • Bases en santé communautaire  <br /> • Socio-anthropologie de la santé <br /> • Santé environnementale <br /> • Approche « One Health » <br /> • Promotion de la santé <br />",
        },
        {
          module: "Module 2",
          title: "PROGRAMMES ET SYSTEMES DE SANTE",
          point:
            "• Organisation du système de santé  <br />• Enjeux de la prévention médicale  <br />• Bases de la sécurité des patients  <br /> • Initiation à la gestion de projets en santé  <br />• Initiation au suivi-évaluation en santé <br /> • Bases en économie de la santé  <br />",
        },
        {
          module: "Module 3",
          title: "RECHERCHE EN SANTE PUBLIQUE",
          point:
            "• Bases en épidémiologie  <br />• Enquêtes épidémiologiques <br />• Recherche documentaire en santé <br />• Utilisation des données probantes <br />• Rédaction du protocole de recherche <br />• Bases en recherche qualitative <br />• Ethique de la recherche en santé <br />• Communication médico-scientifique <br />",
        },
      ],
    };
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
@import "src/assets/styles/sass/variables.scss";

.testimony::v-deep {
  .carousel {
    .carousel__track {
      justify-content: space-between !important;
    }
    .carousel__pagination {
      .carousel__pagination-button {
        background-color: #16b853 !important;
        border-radius: 9999px !important;
        width: 5px;
        height: 5px;
      }
      .carousel__pagination-button--active {
        background-color: white !important;
        border: 2.5px solid #16b853 !important;
        width: 10px;
        height: 10px;
      }
    }
  }
}
.car-item:hover {
  transition: width 2s;
}
@media screen and (max-width: 767px) {
  .testimony::v-deep {
    .carousel {
      .carousel__slide {
        width: 100% !important;
      }
    }
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .testimony::v-deep {
    .carousel {
      .carousel__track {
        justify-content: space-between !important;
      }
      .carousel__slide {
        width: 50% !important;
      }
    }
  }
}
@media screen and (min-width: 1024px) and (max-width: 1279px) {
  .testimony::v-deep {
    .carousel {
      .carousel__track {
        justify-content: space-between !important;
      }
      .carousel__slide {
        width: 33% !important;
      }
    }
  }
}
</style>
